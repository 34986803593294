import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import './layout.css'

import { css } from '@emotion/core'

import 'typeface-noto-serif'
import 'typeface-lato'

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
						siteUrl
					}
				}
			}
		`}
		render={data => (
			<>
				<Helmet
					title={data.site.siteMetadata.title}
					meta={[
						{ name: 'description', content: 'Toast' },
						{
							name: 'keywords',
							content: 'toast, toast.ninja, github, slack, pull requests',
						},
					]}
				>
					<html lang="en" />
					<link rel="canonical" href={data.site.siteMetadata.siteUrl} />
					{/* <!-- Global site tag (gtag.js) - Google Ads: 306983406 --> */}
					<script async src="https://www.googletagmanager.com/gtag/js?id=AW-306983406"></script>

					{/* inline script elements */}
					<script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-306983406');
						console.log('Initialized Google Tag manager script', 'AW-306983406')
          `}</script>

					<script>{`
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-5GHLHLR');
					`}</script>
				</Helmet>
				<div>hello</div>
				{/* <noscript> */}
				{/* <iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-5GHLHLR"
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				></iframe> */}
				{/* </noscript> */}

				<div
					css={css`
						grid-area: space;
					`}
				/>
				<div css={containerClass}>
					<Header
						siteTitle={data.site.siteMetadata.title}
						css={css`
							grid-area: header;
							position: fixed;
							z-index: 1;
							width: 100%;
							top: 0;
							left: 0;
						`}
					/>
					<div css={bodyClass}>{children}</div>
					<Footer
						css={css`
							grid-area: footer;
						`}
					/>
				</div>
			</>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

const containerClass = css`
	display: grid;
	grid-template-columns: 1fr 10fr 1fr;
	grid-template-rows:
		3rem /* header */
		1fr /* main */
		8rem /* footer */;
	grid-template-areas:
		'header header header'
		'. main .'
		'footer footer footer';
	min-height: 100vh;
	margin: auto;
	align-content: space-between;
	justify-content: space-around;

	@media screen and (max-width: 600px) {
		grid-template-columns: 1fr;
		grid-template-rows:
			4rem /* header */
			1fr /* main */
			8rem /* footer */;
		grid-template-areas:
			'header'
			'main'
			'footer';
	}
`

const bodyClass = css`
	grid-area: main;
	margin: 0 auto;
	width: 80vw;
	padding: 0px 0.5rem 1rem;
	padding-top: 0;
	padding-top: 3rem;

	@media screen and (max-width: 600px) {
		width: 90vw;
	}
`

export default Layout
