import Typography from 'typography'

const BASE_FONT_SIZE_PX = 24
const FONT_SCALE = 1.333

const toRems = sizePx => Math.round(sizePx) / BASE_FONT_SIZE_PX

const scale = (number, scaleOperation) => {
  const scaleTo = number + 1
  let size = BASE_FONT_SIZE_PX

  for (let i = 1; i < scaleTo; i++) {
    size = scaleOperation(size, FONT_SCALE)
  }

  return toRems(size)
}

const scaleUpOperation = (size, scale) => size * scale
const scaleDownOperation = (size, scale) => size / scale

const scaleBaseSizeUp = number => scale(number, scaleUpOperation)
const scaleBaseSizeDown = number => scale(number, scaleDownOperation)

const typography = new Typography({
  baseFontSize: `${BASE_FONT_SIZE_PX}px`,
  baseLineHeight: 1.666,
  headerWeight: 900,
  headerFontFamily: ['Noto Serif', 'serif'],
  bodyFontFamily: ['Lato', 'sans-serif'],
})

export const fontSizes = {
  small3: `${scaleBaseSizeDown(3)}rem`,
  small2: `${scaleBaseSizeDown(2)}rem`,
  small1: `${scaleBaseSizeDown(1)}rem`,
  normal: `1rem`,
  large1: `${scaleBaseSizeUp(1)}rem`,
  large2: `${scaleBaseSizeUp(2)}rem`,
  large3: `${scaleBaseSizeUp(3)}rem`,
  large4: `${scaleBaseSizeUp(4)}rem`,
}

export const pxToRem = pixels => pixels / BASE_FONT_SIZE_PX

export const rem = pixels => `${pixels / BASE_FONT_SIZE_PX}rem`

export default typography
