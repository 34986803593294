import chroma from 'chroma-js'

export const darken = (color, ratio) =>
  chroma(color)
    .darken(ratio)
    .hex()

export const lighten = (color, ratio) =>
  chroma(color)
    .brighten(ratio)
    .hex()
