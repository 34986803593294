import React from 'react'
import { Link } from 'gatsby'
import { ClassNames } from '@emotion/core'
import { fontSizes } from 'utils/typography'
import { darken } from 'utils/colors'

const Footer = ({ className }) => (
  <ClassNames>
    {({ css, cx }) => (
      <div
        css={cx(
          className,
          css`
            background: #333;
            height: 8rem;
            padding-top: 1rem;
          `
        )}
      >
        <div
          css={css`
            margin: 0 auto;
            max-width: 960;
            padding: 0.5rem 1.0875rem;
            color: silver;
          `}
        >
          <span
            css={css`
              font-size: ${fontSizes.small2};
              color: ${darken('silver', 1)};
            `}
          >
            <ul
              css={css`
                list-style: none;
                margin: 0;
                > li {
                  margin-bottom: 0.2rem;
                }
              `}
            >
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                {' '}
                <Link to="/legal/privacy-policy">Privacy Policy</Link>{' '}
              </li>
              <li>
                <Link to="/legal/terms-of-service">Terms of Service</Link>
              </li>
              <li>
                <Link to="/legal/cookie-policy">Cookie Policy</Link>
              </li>
              <li>MADE WITH ♥ IN SAN FRANCISCO</li>
            </ul>
          </span>
          <h4 style={{ margin: 0 }} />
        </div>
      </div>
    )}
  </ClassNames>
)

export default Footer
