import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { colors } from 'theme'

// import { darken, lighten } from 'utils/colors'
import './nav.css'
import { rem, fontSizes } from 'utils/typography'
import StickersTypeformPopup from './buttons/get-toast-stickers.typeform'

const Logo = () => (
  <div
    css={css`
      width: 5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1px;

      @media screen and (max-width: 600px) {
        width: 3.5rem;
      }

      img {
        margin: 0;
      }
    `}
  >
    <img src="/images/toast-header.svg" />
  </div>
)

const Header = ({ siteTitle, className }) => {
  return (
    <nav
      className={className}
      css={css`
        ${ '' /* background: white; */ }
        color: #222;
        height: auto;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        background-color: white;
        box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
      `}
    >
      <div
        className="nav"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 80vw;

          @media screen and (max-width: 600px) {
            width: 90vw;
          }

          margin: auto;
        `}
      >
        <div className="nav-header">
          <div className="nav-title">
            <Link
              to="/"
              css={css`
                text-decoration: none;
                display: flex;
                line-height: 2rem;
                align-items: center;
              `}
            >
              <Logo />
              {/* <div
                css={css`
                  margin-left: 0.5rem;
                  position: relative;
                  top: ${rem(-1)};
                  font-family: 'Noto Serif', sans-serif;
                  font-weight: 900;
                  font-size: ${fontSizes.medium};
                `}
              >
                Toast
              </div> */}
            </Link>
          </div>
        </div>
        <div className="nav-btn">
          <label
            htmlFor="nav-check"
            css={css`
              width: ${ rem(50) };
              height: ${ rem(50) };
              padding: ${ rem(13) };

              span {
                display: block;
                width: ${ rem(25) };
                height: ${ rem(10) };
                border-top: ${ rem(2) } solid #222;
              }
            `}
          >
            <span />
            <span />
            <span />
          </label>
        </div>
        <input type="checkbox" id="nav-check" />
        <div
          className="nav-links"
          css={css`
            color: white;
            display: flex;
            align-items: center;
            a {
              color: ${ colors.textHsla };
              font-size: 0.8rem;
            }
            a:hover {
              color: ${ colors.primary };
            }
          `}
        >
          <Link to="/how-it-works" target="_blank">
            How It Works
          </Link>
          <Link to="/pricing" target="_blank">
            Pricing
          </Link>
          <Link to="/enterprise" target="_blank">
            Enterprise
          </Link>
          <StickersTypeformPopup />
          {/* <div>
            <SignInWithGithubButton text="Sign In with GitHub" />
          </div> */}
        </div>
      </div>
    </nav>
  )
}

export default Header
