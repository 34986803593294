import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { rem } from 'utils/typography'

import { css } from '@emotion/core'
import { colors } from 'theme'

class StickersTypeformPopup extends React.Component {
  constructor(props) {
    super(props)
    this.openForm = this.openForm.bind(this)
  }

  openForm() {
    this.typeformEmbed.typeform.open()
  }

  render() {
    return (
      <div
        className="StickersTypeformPopup"
        css={css`
          display: flex;
          @media screen and (max-width: 700px) {
            display: none;
          }
        `}
      >
        <ReactTypeformEmbed
          popup
          mode="drawer_right"
          autoOpen={false}
          url="https://rea671664.typeform.com/to/x21tFK"
          hideHeaders
          hideFooter
          buttonText="Get Toast Stickers!"
          style={{ width: 'auto' }}
          ref={tf => {
            this.typeformEmbed = tf
          }}
        />
        <button
          className="btn"
          onClick={this.openForm}
          css={css`
            cursor: pointer;
            display: inline-block;
            font-size: ${rem(18)};
            color: gray;
            margin: 1rem;
            height: ${rem(36)};
            padding: 0 ${rem(16)};
            border-radius: ${rem(20)};
            border-width: ${rem(1)};
            border-color: #cccccc;

            &:hover {
                color: ${colors.primary};
                border-color: ${colors.primary};
          `}
        >
          Get Toast Stickers!
        </button>
      </div>
    )
  }
}

export default StickersTypeformPopup
